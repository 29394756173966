let awsRegion = 'us-west-2';

export default {
 api_urls: {
   reporting: 'https://jlh68lt5nl.execute-api.us-west-2.amazonaws.com/api',
   geojson: 'https://fpxetd3g90.execute-api.us-west-2.amazonaws.com/api',
   overlays: 'https://w345mw5m2h.execute-api.us-west-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.newmont.forwoodsafety.com',
 forwood_id_url: 'https://id.newmont.forwoodsafety.com?redirect_uri=https://geoeditor.newmont.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.newmont.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-west-2_wGCB0IPfa',
   userPoolWebClientId: '16h68ts2jqsaqt1d30op1jum83',
   cookieStorage: {
     domain: '.newmont.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'True',
 env: 'prod'
};
